<template>
    <div class="phone">
        <img src="@/assets/img/phone.png" alt="" class="phone-img">
         <iframe class="iframe"  ref="iframe" frameborder="0" src="https://xeu.xeform.cn/xedesign/h5/showworks?workno=W4981000143" scrolling="yes" id="myiframe"></iframe>
         <div class="prev" @click="hanleSetMessage('prev')">上一页</div>
         <div class="next"  @click="hanleSetMessage('next')">下一页</div>
         <div class="code">
             <p>扫一扫，分享给朋友！</p>
<vue-qr text="https://xeu.xeform.cn/xedesign/h5/showworks?workno=W4981000143" :margin="0" :logoSrc='logoSrc' colorLight="#fff" :size="120"></vue-qr>

         </div>
    </div>
</template>
<script>
import vueQr from 'vue-qr'
export default {
    data(){
        return{
             logoSrc:require("../../assets/icon.svg")
        }
    },
    methods:{
            hanleSetMessage(type){
                console.log(type);
      this.$refs.iframe.contentWindow.postMessage(type,'*')
      // console.log(this.url +(this.prodid===1?'hb/':this.prodid===2?'h5/':'form/') + 'work?workno='+this.tcode);
    },
    },
      components:{
    vueQr
  },
}
</script>
<style lang="less" scoped>
.phone{
    width: 336px;
    height: 734px;
    margin: 0 auto;
    position: relative;
                box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.4);
                border-radius: 46px;
    .phone-img{
        width: 100%;
        height: 100%;
    }
    .iframe{
        position: absolute;
        top: 105px;
        left: 8px;
     width: 320px;
    height: 588px;
     box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.1);
     border-radius: 0 0 21px 21px;
    }
    .prev{
background-color: #eee;
position: absolute;
right: -30px;
top: 250px;
width: 30px;
font-size: 16px;
text-align: center;
padding: 10px 0;
border-top-right-radius:3px;
border-bottom-right-radius:3px;
cursor: pointer;
    }
    .next{
        background-color: #eee;
position: absolute;
right: -30px;
bottom: 210px;
width: 30px;
font-size: 16px;
text-align: center;
padding: 10px 0;
border-top-right-radius:3px;
border-bottom-right-radius:3px;
cursor: pointer;
    }
    .prev:hover{
        background-color: #08a1ef;
        color: #fff;

    }
      .next:hover{
        background-color: #08a1ef;
        color: #fff;
    }
    .code{
        position: absolute;
        top: 50px;
        right: -300px;
        background-color: #fff;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.4);
        text-align: center;
        border-radius: 10px;
        padding: 20px 30px;
    }
}
</style>